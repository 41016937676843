import React, { useCallback, useState, useRef } from 'react';
import { mergeRefs } from 'use-callback-ref';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import Button from '@atlaskit/button';
import type { TriggerProps, ContentProps } from '@atlaskit/popup';
import { useIntl } from '@atlassian/jira-intl';
import { JiraPopup as Popup } from '@atlassian/jira-popup/src/ui/jira-popup.tsx';
import { fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import { AsyncUpdateFilterDialog as UpdateFilterDialog } from '@atlassian/jira-save-filter-dialog/src/ui/update-filter-dialog/async.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { useModalDialogActions } from '@atlassian/jira-software-modal-dialog/src/controllers/index.tsx';
import messages from './messages.tsx';
import { PopupContent, preloadPopupContentComponent } from './popup-content/async.tsx';

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export { DetailsButtonHeaderWrapper } from './styled';

export type Props = {
	filterId: string;
	defaultOpen?: boolean;
};

export const DetailsPopup = ({ filterId, defaultOpen = false }: Props) => {
	const { formatMessage } = useIntl();
	const { setReturnFocusTo } = useModalDialogActions();
	const triggerRef = useRef<HTMLButtonElement | null>(null);
	const [isOpen, setIsOpen] = useState(defaultOpen);
	const onClose = useCallback(() => setIsOpen(false), [setIsOpen]);
	const titleId = 'issue-navigator-custom-filters.ui.details-popup.popup.title';
	const onTriggerClick = useCallback(
		(_: unknown, analyticsEvent: UIAnalyticsEvent) => {
			setIsOpen(!isOpen);
			fireUIAnalytics(analyticsEvent, 'filterDetailsButton');
		},
		[isOpen, setIsOpen],
	);

	const [isEditFilterModalOpen, setIsEditFilterModalOpen] = useState(false);

	const onOpenEditFilterModal = useCallback(() => {
		setIsEditFilterModalOpen(true);
		onClose();
	}, [onClose]);

	const onCloseEditFilterModal = useCallback(() => {
		setIsEditFilterModalOpen(false);
		fg('jfp_a11y_issue_navigator_detail_keyboard_focus') && setReturnFocusTo(triggerRef);
	}, [setReturnFocusTo]);

	const Content = useCallback(
		(props: ContentProps) => (
			<PopupContent
				{...props}
				{...(fg('jfp-a11y-team_return-focus-to-filter-details') && { triggerRef })}
				onOpenEditFilterModal={onOpenEditFilterModal}
				onOpenNewSubscriptionModal={onClose}
				filterId={filterId}
				titleId={titleId}
			/>
		),
		[filterId, onClose, onOpenEditFilterModal],
	);
	const Trigger = useCallback(
		(props: TriggerProps) => {
			const ref = mergeRefs([props.ref, triggerRef]);
			return (
				<Button
					{...props}
					appearance="link"
					onClick={onTriggerClick}
					isSelected={isOpen}
					onMouseOver={preloadPopupContentComponent}
					testId="issue-navigator-custom-filters.ui.details-popup.button"
					{...(fg('jfp_a11y_issue_navigator_detail_keyboard_focus') ? { ref } : {})}
				>
					{formatMessage(messages.filterDetails)}
				</Button>
			);
		},
		[isOpen, onTriggerClick, formatMessage],
	);

	return (
		<>
			{!fg('nin-save-filter-dialog-entry-point') && (
				<UpdateFilterDialog
					filterId={filterId}
					onClose={onCloseEditFilterModal}
					isOpen={isEditFilterModalOpen}
				/>
			)}
			<Popup
				isOpen={isOpen}
				onClose={onClose}
				shouldRenderToParent={fg('jfp_a11y_team_fix_filter_details_reading_order')}
				placement="bottom-start"
				messageId="issue-navigator-custom-filters.ui.details-popup.popup"
				messageType="transactional"
				content={Content}
				trigger={Trigger}
				testId="issue-navigator-custom-filters.ui.details-popup.popup"
				role="dialog"
				titleId={titleId}
			/>
		</>
	);
};
